<template>
  <div>
    <div class="row  index-icon justify-content-md-center ">
      <i class="rc-Ativo-56 "></i>
    </div>
    <div class="row justify-content-md-center index-card">
      <div class="col-md-10">
        <div class="row justify-content-md-center">
          <!-- Processos -->
          <div class="d-flex col-md-3">
            <div class="card principal">
              <router-link :to="{name: 'KpiProcesses'}" style="height: 100%">
                <div class="card-body d-flex align-items-center justify-content-center">
                  <h5 class="box-title mb-2">{{ $t('kpi.process.title') }}</h5>
                </div>
              </router-link>
            </div>
          </div>
          <!-- Volume -->
          <div class="d-flex col-md-3">
            <div class="card principal">
              <router-link :to="{name: 'KpiVolumes'}" style="height: 100%">
                <div class="card-body d-flex align-items-center justify-content-center">
                  <h5 class="box-title mb-2">{{ $t('kpi.volume.title') }}</h5>
                </div>
              </router-link>
            </div>
          </div>
          <!-- Origens/Destinos -->
          <div class="d-flex col-md-3">
            <div class="card principal">
              <router-link :to="{name: 'KpiOriginDestination'}" style="height: 100%">
                <div class="card-body d-flex align-items-center justify-content-center">
                  <h5 class="box-title mb-2">{{ $t('kpi.origin_destination.title') }}</h5>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FinantialIndex',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' - %s'
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    }
  },
  watch: {
    user: function () {
    }
  }
}
</script>
